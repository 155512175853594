import { createStore } from 'vuex'

export default createStore({
  state: {
    // 存储token
    Authorization: '',
    userId: '',
    userInfo: {},
    isLogin: false
  },
  mutations: {
    //存储Token 到localStorage和vuex
    ['setToken'](state, token) {
      localStorage.setItem("Authorization", token);
      state.Authorization = token;
      state.isLogin = true;
      localStorage.setItem("isLogin", state.isLogin);
    },
    //存储userId 到localStorage和vuex
    ['setUserId'](state, userId) {
      localStorage.setItem("userId", userId);
      state.userId = userId;
    },
    //存储userInfo 到localStorage和vuex
    ['setUserInfo'](state, user) {
      localStorage.setItem("userInfo", JSON.stringify(user));
      state.userInfo =JSON.stringify(user);
    }

  },
  actions: {
  },
  modules: {
  }
})
