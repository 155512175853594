import axios from 'axios'

const $https = axios.create({
  timeout: 5000,
  headers: {
    'X-Custom-Header': 'foobar'
  }
});

// 请求拦截器
$https.interceptors.request.use(function (config) {
  // 在发送请求之前验证token
  config.headers.Authorization= localStorage.getItem('Authorization')
  return config;
}, function (error) {
  // 请求错误
  return Promise.reject(error);
});

// 添加响应拦截器
$https.interceptors.response.use(function (response) {
  // 响应数据
  let data = response.data;
  return data;
}, function (error) {
  // 响应错误
  return Promise.reject(error);
});


export default $https



