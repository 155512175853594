// import { createRouter, createWebHistory } from 'vue-router'
import { createRouter,createWebHashHistory } from 'vue-router'
import Home from '../views/Home'
import Login from '../views/Login/Login'

const routes = [
  {
    path: '/',
    redirect:'/login',
    meta:{
      requireAuth: false, // 判断是否需要登录
      isShow:false
    }
  },
  {
    path: '/login',
    name: 'Login',
    component: Login,
    meta:{
      title:"登录",
      requireAuth: false
    }
  },
  {
    path: '/home',
    name: 'Home',
    component: Home,
    meta:{
      title:"首页",
      requireAuth: true
    },
    children:[
      {
        path: '/personal',
        name: 'Personal',
        component: () => import(/* webpackChunkName: "personal" */ '../views/Personal/Personal'),
        meta:{
          title:"个人中心",
          requireAuth: true
        }
      },
    ]
  },
  {
    path: '/about',
    name: 'About',
    component: () => import(/* webpackChunkName: "about" */ '../views/About.vue'),
    meta:{
      title:"关于",
      requireAuth: false
    }
  }
]

const router = createRouter({
  // history: createWebHistory(process.env.BASE_URL),
  history: createWebHashHistory(),

  routes
})

export default router
