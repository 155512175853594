import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import installElementPlus from './plugins/element'
// //富文本依赖
// import QuillEditor from 'vue-quill-editor'
// import 'quill/dist/quill.core.css'
// import 'quill/dist/quill.bubble.css'
// import 'quill/dist/quill.snow.css'


const app = createApp(App)
installElementPlus(app)
// app.use(QuillEditor)
app.use(store).use(router).mount('#app')

router.beforeEach((to, from, next) => {
    let {
        title,
        requireAuth
    } = to.meta;
    // // 从vuex获取登录状态
    // let isLogin = store.state.isLogin;
    let Logined = localStorage.getItem('isLogin')
    document.title = title;
    if ( requireAuth && !Logined) {
        next({
            path: '/login'
        })
    } else {
        next();
    }
})