<template>
  <div class="login">
    <div class="loginForm">
      <h3>长江三峡文化</h3>
      <el-form :model="loginForm" :rules="rules" label-width="60px">
        <el-form-item label="账号:" prop="name" class="el-form-item">
          <el-input
            placeholder="请输入账号"
            v-model="loginForm.name"
            clearable
            size="medium"
          ></el-input>
        </el-form-item>
        <el-form-item label="密码:" prop="password" class="el-form-item">
          <el-input
            type="password"
            v-model="loginForm.password"
            placeholder="请输入密码"
            clearable
            show-password
          ></el-input>
        </el-form-item>
        <el-form-item class="el-form-item">
          <el-button class="login-btn" type="primary" @click="login"
            >登录</el-button
          >
        </el-form-item>
      </el-form>
    </div>
  </div>
</template>

<script>
import { userLogin } from "../../api/api";
import store from "../../store";
import { useRouter } from "vue-router";
import { reactive } from "vue";
export default {
  name: "Login",
  setup() {
    let router = useRouter();
    let loginForm = reactive({
      name: "",
      password: "",
    });
    let rules = reactive({
      name: [
        { required: true, message: "请输入账号", trigger: "blur" },
        { max: 9, message: "请输入正确的账号", trigger: "blur" },
      ],
      password: [{ required: true, message: "请输入密码", trigger: "blur" }],
    });
    let login = () => {
      let user = {
        name: loginForm.name,
        password: loginForm.password,
      };
      userLogin(user).then((res) => {
        store.commit("setToken", res.token);
        store.commit("setUserId", res.user._id);
        store.commit("setUserInfo", res.user);
        localStorage.setItem(
          "tokenTime",
          new Date().getTime() + 1 * 3600 * 1000
        );
        router.push({ path: "/home", query: { name: res.user.name } });
      });
    };

    return {
      loginForm,
      rules,
      login,
    };
  },
};
</script>

<style lang="scss">
.login {
  width: 100vw;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  background-image: linear-gradient(
    to right,
    rgba(218, 169, 215, 0.637),
    rgba(128, 174, 235, 0.904)
  );
  text-align: center;
  .loginForm {
    width: 400px;
    height: auto;
    margin: 200px auto;
    border: 2px solid #efefef;
    border-radius: 10px;
    font-size: 18px;
    text-align: center;
  }
  .el-form-item {
    margin-left: 5%;
    margin-right: 10%;
  }

  .login-btn {
    width: 100%;
  }
}
</style>