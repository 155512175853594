import $https from "./index"
import $http from "./http"

//登录
export const userLogin = data => {
    return $http.post('/api/users/login', data);
}
//获取用户信息
export const getUserInfo = $https.get('/api/users/current');

//获取IP地址
export const getip = $http.post('/api/ip/cityjson?ie=utf-8',{});


