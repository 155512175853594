<template>
  <div class="home">
    <el-container>
      <el-header class="header">
        <el-row :gutter="18">
          <el-col :span="4" class="logoBox">
            <div class="grid-content bg-purple">
              <img class="logo" src="../assets/logo.png" alt="" />
            </div>
          </el-col>
          <el-col :span="16" class="titleBox">
            <div class="grid-content bg-purple">长江三峡文化后台管理系统</div>
          </el-col>
          <el-col :span="4" class="infoBox">
            <div class="grid-content bg-purple">
              <div class="avatar">
                <el-dropdown>
                  <el-avatar :src="user.avatar"> </el-avatar>
                  <template #dropdown>
                    <el-dropdown-menu>
                      <el-dropdown-item> {{ user.name }} </el-dropdown-item>
                      <el-dropdown-item @click="logout">注销</el-dropdown-item>
                    </el-dropdown-menu>
                  </template>
                </el-dropdown>
              </div>
            </div>
          </el-col>
        </el-row>
      </el-header>
      <el-container>
        <el-aside width="150px">
          <el-menu
            :router="true"
            default-active="2"
            class="el-menu-vertical-demo"
          >
            <el-menu-item
              v-for="i in tabList"
              :key="i.name"
              :index="i.name"
              :route="i.path"
            >
              <i class="el-icon-s-custom"></i>
              <template #title>{{ i.meta.title }}</template>
            </el-menu-item>
          </el-menu>
        </el-aside>
        <el-container>
          <el-main>
            <el-breadcrumb separator-class="el-icon-arrow-right">
              <el-breadcrumb-item :to="{ path: '/home' }"
                >首页</el-breadcrumb-item
              >
              <el-breadcrumb-item>活动管理</el-breadcrumb-item>
            </el-breadcrumb>
            <router-view />
          </el-main>
          <el-footer>
            <!-- <el-button type="primary" @click="ip">登录</el-button> -->
            Footer</el-footer
          >
        </el-container>
      </el-container>
    </el-container>
  </div>
</template>

<script>
import { useRouter } from "vue-router";
import { tokenExpressInTime } from "../utils/util";
import { ElMessage } from "element-plus";
export default {
  name: "Home",
  components: {},
  setup() {
    let router = useRouter();
    // 页面加载验证token过期时间
    if (tokenExpressInTime()) {
      ElMessage({
        type: "error",
        center: true,
        message: "登录超时,请重新登录!",
        duration: 2000,
      });
      setTimeout(() => {
        localStorage.clear();
        router.push("/login");
      }, 1500);
    }
    let user = JSON.parse(localStorage.getItem("userInfo"));
    let tabList = router.options.routes[2].children;
    let logout = () => {
      localStorage.clear();
      router.push("/login");
    };
    return {
      logout,
      tabList,
      user,
    };
  },
};
</script>
<style lang="scss">
  

/* .el-header {
  background-color: #b3c0d1;
  color: #333;
  text-align: center;
  line-height: 76px;
} */
/* .header {
  background-color: #efefef;
  padding: 5px;
} */
.logoBox {
  width: 50px;
}
.logo {
  width: 50px;
}
.titleBox {
  text-align: center;
  justify-content: center;
  font-size: 26px;
}
.infoBox {
  text-align: right;
  justify-content: right;
}

.username {
  color: #ff0000;
}
.el-header,
.el-footer {
  background-color: #b3c0d1;
  color: #333;
  text-align: center;
  line-height: 60px;
}

.el-aside {
  background-color: #d3dce6;
  color: #333;
  text-align: center;
  line-height: 200px;
}

.el-main {
  background-color: #e9eef3;
  color: #333;
  text-align: center;
  line-height: 160px;
}

body > .el-container {
  margin-bottom: 40px;
}

.el-container:nth-child(5) .el-aside,
.el-container:nth-child(6) .el-aside {
  line-height: 260px;
}

.el-container:nth-child(7) .el-aside {
  line-height: 320px;
}
</style>