import axios from 'axios'

const $http = axios.create({
  timeout: 5000,
  headers: {
    'X-Custom-Header': 'foobar'
  }
});

// 请求拦截器
$http.interceptors.request.use(function (config) {
  return config;
}, function (error) {
  // 请求错误
  return Promise.reject(error);
});

// 添加响应拦截器
$http.interceptors.response.use(function (response) {
  // 响应数据
  let data = response.data;
  return data;
}, function (error) {
  // 响应错误
  return Promise.reject(error);
});


export default $http
